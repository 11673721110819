import React from 'react';

import './style.scss';

interface Props {
  className?: string;
}

const MenuDivider = ({ className = '' }: Props) => <div className={`Menu-Divider ${className}`} />;

export default React.memo(MenuDivider);
