import type {
  ComponentProps,
  ElementType,
  KeyboardEventHandler,
  MouseEventHandler,
  ReactChild,
  ReactNode,
} from 'react';

import React, { useCallback, useMemo, useRef } from 'react';
import flattenChildren from 'react-keyed-flatten-children';
import { Link, NavLink } from 'react-router-dom';

import classy from '@core/utils/classy';

import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';

import Menu from '..';
import '../style.scss';

type TagElement<E extends ElementType> = E extends keyof HTMLElementTagNameMap
  ? HTMLElementTagNameMap[E]
  : E extends keyof SVGElementTagNameMap
    ? SVGElementTagNameMap[E]
    : E extends Link | NavLink
      ? HTMLElementTagNameMap['a']
      : HTMLElement;

type AnchorProps = ComponentProps<'a'>;
type ButtonProps = ComponentProps<'button'>;
type DivProps = ComponentProps<'div'>;
type ImageProps = ComponentProps<'img'>;
type LinkProps = ComponentProps<typeof Link>;

interface Props<E extends ElementType>
  extends Pick<AnchorProps, 'target'>,
    Pick<DivProps, 'children' | 'className' | 'id' | 'role' | 'style' | 'tabIndex' | 'title'> {
  /* The element type to render the item as. For example, 'button'. */
  TagName?: E | ElementType;
  /* Whether the item is active which renders additional styles like a checkmark */
  active?: boolean;
  alignIcon?: 'left' | 'right';
  color?: 'blue' | 'green' | 'purple' | 'red' | 'yellow';
  /* Additional information about the item to be rendered under the main content */
  description?: ReactNode;
  disabled?: boolean;
  focusable?: boolean;
  href?: string;
  icon?: ReactNode;
  img?: Pick<ImageProps, 'alt' | 'height' | 'src' | 'width'> & {
    border?: boolean;
  };
  /* Name of the item when rendered as a button */
  name?: ButtonProps['name'];
  onClick?: MouseEventHandler<TagElement<E>>;
  onKeyPress?: KeyboardEventHandler<TagElement<E>>;
  /* Direction to open any submenus */
  openAt?: 'left' | 'right';
  to?: LinkProps['to'];
}

const MenuItem = <E extends ElementType = 'div'>(props: Props<E>) => {
  const {
    TagName = 'div',
    active,
    alignIcon = 'left',
    children,
    className = '',
    color,
    description,
    disabled,
    focusable = true,
    href,
    icon,
    img,
    onClick,
    onKeyPress,
    openAt = 'right',
    target,
    title,
    style,
    ...attrs
  } = props;
  const rootRef = useRef<TagElement<E>>(null);

  // Iterate through children to see if a submenu exists or not. Use the first
  // Menu instance that it finds, and use all other symbols for the label.
  const { content, menu: subMenu } = useMemo(
    () =>
      flattenChildren(children).reduce<{ content: ReactChild[]; menu: ReactChild | null }>(
        (prev, curr) => {
          if (React.isValidElement(curr) && curr.type === Menu) {
            return prev.menu ? prev : { ...prev, menu: curr };
          }

          return { ...prev, content: [...prev.content, curr] };
        },
        { content: [], menu: null },
      ),
    [children],
  );

  const linkTags: ElementType[] = ['div', 'button', 'a', 'label', Link, NavLink];
  const menuItemClasses = classy(
    'Menu-Item',
    alignIcon === 'left' ? 'Menu-Item-alignIcon_left' : 'Menu-Item-alignIcon_right',
    linkTags.includes(TagName) && focusable && 'Menu-Item_link',
    active && 'Menu-Item_active',
    className,
    color && `Menu-Item_${color}`,
    description && 'Menu-Item_description',
    disabled && 'Menu-Item_link_disabled',
    href && target === '_blank' && alignIcon === 'left' && 'Menu-Item_external',
    subMenu && 'Menu-Item_arrow',
  );

  const handleKeyDown = useCallback(
    e => {
      if ((e.key !== ' ' && e.key !== 'Enter') || disabled) return;
      e.preventDefault();
      if (rootRef.current && 'click' in rootRef.current) {
        rootRef.current.click();
      }
    },
    [disabled],
  );

  const renderedIcon = useMemo(() => (typeof icon === 'string' ? <i className={icon} /> : icon), [icon]);

  if (subMenu) {
    return (
      <Dropdown align={openAt} justify="start" trigger="hover">
        <div
          {...attrs}
          className={menuItemClasses}
          role="button"
          {...(focusable ? { tabIndex: disabled ? -1 : 0 } : {})}
        >
          {renderedIcon}
          {content}
        </div>
        <div className={`Menu-Item-SubMenu Menu-Item-SubMenu_${openAt}`}>{subMenu}</div>
      </Dropdown>
    );
  }

  const imgMenuItemClasses = classy('Menu-Item_img', img?.border && 'Menu-Item_img__border');

  return (
    <TagName
      {...(onClick && !disabled && { onClick, onKeyDown: handleKeyDown, role: 'button', tabIndex: 0 })}
      onKeyPress={onKeyPress}
      {...attrs}
      ref={rootRef}
      className={menuItemClasses}
      disabled={disabled}
      href={href}
      rel={target === '_blank' ? 'noreferrer' : null}
      style={style}
      target={target}
      title={title}
      {...(focusable ? { tabIndex: disabled ? -1 : 0 } : {})}
    >
      {!!img && (
        <img alt={img.alt || ''} className={imgMenuItemClasses} height={img.height} src={img.src} width={img.width} />
      )}
      {alignIcon === 'left' && renderedIcon}
      {description ? (
        <Flex align="stretch" gap="3px" layout="col">
          {!!children && (
            <Flex gap="xs" justify="between">
              {content}
            </Flex>
          )}
          {!!description && <div className="Menu-Item-description">{description}</div>}
        </Flex>
      ) : (
        content
      )}
      {alignIcon === 'right' && renderedIcon}
      {!!href && target === '_blank' && alignIcon === 'left' && (
        <Icon className="Menu-item-external-link-icon" name="arrow-up-right" />
      )}
    </TagName>
  );
};

export default React.memo(MenuItem) as typeof MenuItem;
